/* You can add global styles to this file, and also import other style files */

@use "./assets/themes/common.scss";
@use "./assets/themes/snl-complib.scss";
@use "./assets/themes/spacing.scss";
@use "./assets/themes/theme-colors.scss";
@use "./assets/themes/typography.scss";
@use "./assets/themes/custome-style.scss";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
@import 'ckeditor5/ckeditor5.css';
.breadcrumb-main {
    padding: var(--spacing-32) var(--spacing-0);
}
.navigation__panel-content {
    width: 100% !important;
    &.navigation__panel-content--selected {
        width: inherit !important;
    }
  }
  .hiddenCanvasElement{
    display: none;
  }

   
.MainCkeditor{
  .ck-editor__editable_inline{
    &.ck-focused{
      box-shadow: none !important;
    }
    ol, ul {
      padding-left: 32px !important;
  }
  }
}

.ck-balloon-panel{
  display: none !important;
}
.capitalize-first{
  &::first-letter {
    text-transform: capitalize;
  }
}
.ck-editor__editable {
  min-height: calc(100vh - 516px);
  @media screen and (max-width: 1400px) {
    min-height: calc(100vh - 360px);
  }
}
.ck-editor__editable_inline{
  padding-left: 24px !important;
}
