/* You can add global styles to this file, and also import other style files */
@import "~@angular/cdk/overlay-prebuilt.css";
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.3.0/css/all.min.css");
@import 'ckeditor5/ckeditor5.css';
.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.body-b3 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resize-date-picker-overlay {
  width: auto !important;
}

.upperinput input {
  text-transform: uppercase;
}

.horizontal-line {
  border-bottom: 1px solid var(--neutral-9);
}

.inner-header {
  flex-direction: row;
  display: flex;
  padding-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}
.inner-header.border {
  border-bottom: var(--spacing-1) solid var(--neutral-9);
}
.inner-header .section-left .profile {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
}
.inner-header .section-left .profile .profile__image {
  width: 48px;
  height: 48px;
}
.inner-header .section-left .profile .profile__image img {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-999);
  border: 1px solid #008ECF;
}
.inner-header .section-left .profile .detail .sub-heading-s1 {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
}
.inner-header .section-left .profile .detail .sub-heading-s1 .word-break {
  word-break: break-word;
}

* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS */
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

snl-page-tab .page-tab:hover {
  background-color: inherit !important;
}

snl-toast {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-right: 20px;
}

.action-panel-main snl-action-panel {
  box-shadow: none;
  padding: var(--spacing-0);
}
@media (max-width: 991px) {
  .action-panel-main {
    display: none;
  }
}

snl-input i {
  color: inherit !important;
}
snl-input.search-input {
  min-width: 280px;
  width: 100%;
}
@media (max-width: 767px) {
  snl-input.search-input {
    width: 100%;
    min-width: inherit;
  }
  snl-input.search-input i {
    color: var(--neutral-6) !important;
  }
}

snl-modal .modal__header {
  position: relative;
}
snl-modal .modal__header .modal__header-icon {
  position: absolute;
  right: 24px;
  top: 24px;
}

snl-table .align-right .heading-text, snl-table .align-right .data-text {
  justify-content: end !important;
}
snl-table .align-right .data-text:not(:empty) {
  max-width: inherit !important;
}
@media (max-width: 991px) {
  snl-table .align-right .heading-text, snl-table .align-right .data-text {
    justify-content: start !important;
  }
}
snl-table.tableCard .label, snl-table.tableCard .d-none {
  display: none;
}
@media (max-width: 991px) {
  snl-table.tableCard .table-heading {
    display: none !important;
  }
  snl-table.tableCard .table__wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  snl-table.tableCard .table__wrapper snl-row {
    background: var(--neutral-12);
    display: flex;
    flex-direction: column;
    border: var(--spacing-1) solid var(--neutral-9);
    border-radius: var(--border-radius-4);
    padding: var(--spacing-16);
    gap: 16px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td {
    border-bottom: 0px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td.td-none {
    display: none;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td.d-none {
    display: block;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .td__wrapper .data-text:not(:empty) {
    max-width: 100% !important;
    padding: var(--spacing-0) !important;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value .label {
    display: block;
  }
  snl-table.tableCard .table__wrapper snl-row snl-td .label-value .value {
    font-family: var(--font-family-roboto-bold);
    font-size: var(--font-size-16);
    line-height: var(--line-height-24);
    letter-spacing: var(--letter-spacing-2);
    font-style: var(--font-style-normal);
    color: var(--font-color-primary);
  }
}
@media (max-width: 991px) and (max-width: 991px) {
  snl-table.tableCard .table__wrapper snl-row snl-td .td__wrapper .text-content {
    white-space: inherit !important;
  }
}

.error .container__dropdown-border:not(.container__dropdown-border--selected) {
  border: var(--border-width-1) solid var(--semantic-9);
}
.error .container__dropdown-border:not(.container__dropdown-border--selected) .container__dropdown--label {
  color: var(--semantic-9);
}
.error .container__dropdown-border:not(.container__dropdown-border--selected) .container__dropdown--label i {
  color: var(--semantic-9);
}
.error .container__dropdown-border:not(.container__dropdown-border--selected) i {
  color: var(--semantic-9);
}

.wrapper .modal__wrapper-small {
  min-width: 700px;
}

.accordion .accordion__header .accordion__icon--expanded {
  display: flex;
  align-items: center;
}

:root {
  /**
  *Spacing
  */
  --spacing-0: 0px;
  --spacing-1: 1px;
  --spacing-2: 2px;
  --spacing-4: 4px;
  --spacing-8: 8px;
  --spacing-12: 12px;
  --spacing-14: 14px;
  --spacing-16: 16px;
  --spacing-24: 24px;
  --spacing-28: 28px;
  --spacing-32: 32px;
  --spacing-36: 36px;
  --spacing-38: 38px;
  --spacing-40: 40px;
  --spacing-48: 48px;
  --spacing-56: 56px;
  --spacing-64: 64px;
  --spacing-72: 72px;
  --spacing-80: 80px;
  --spacing-90: 90px;
}

:root {
  /**
  *Neutral Colors
  */
  --neutral-1: #0F1A2A;
  --neutral-2: #1E2A3B;
  --neutral-3: #27364B;
  --neutral-4: #475569;
  --neutral-5: #64748B;
  --neutral-6: #788496;
  --neutral-7: #94A3B8;
  --neutral-8: #CBD4E1;
  --neutral-9: #DBE3ED;
  --neutral-10: #F1F4F9;
  --neutral-11: #F6F8FC;
  --neutral-12: #FFFFFF;
  --neutral-13: #A3B2C3;
  /**
  *Primary
  */
  --primary-default: #008ECF;
  --primary-hover: #006B9C;
  --primary-selected: #004869;
  --primary-disabled: #CBD4E1;
  --primary-accent: #E5F4FA;
  /**
  *Font Colors
  */
  --font-color-primary: #27364B;
  --font-color-primary-default: #008ECF;
  --font-color-secondary: #64748B;
  --font-color-neutral: #8392A8;
  --font-color-disabled: #B8BFCA;
  --font-color-button: #FFFFFF;
  --font-color-black: #000000;
  /**
  *Pastel Colors
  */
  --pastel-1: #FFADAD;
  --pastel-2: #FEC89A;
  --pastel-3: #7FC8F8;
  --pastel-4: #E0D2FF;
  --pastel-5: #FFE8B7;
  --pastel-6: #C7F5E3;
  --pastel-7: #DAD1CA;
  --pastel-8: #FCF3D3;
  --pastel-9: #ECCBD9;
  --pastel-10: #AFEFF5;
  /**
  *Semantic Colors
  */
  --semantic-1: #0C9E0B;
  --semantic-2: #E8FCF1;
  --semantic-3: #A5E1BF;
  --semantic-4: #00401C;
  --semantic-5: #FFA300;
  --semantic-6: #FFF5D5;
  --semantic-7: #FFDE81;
  --semantic-8: #724B00;
  --semantic-9: #CF0027;
  --semantic-10: #FFEBEB;
  --semantic-11: #FC9595;
  --semantic-12: #8C0000;
  /**
  * Scroller Colors
  */
  --scroll-background: #fafafa;
  --scroll-border: #e8e8e8;
  --scroll-thumb-background: #d9d9d9;
  --scroll-thumb-hover: #7d7d7d;
  /**
  * backdrop background
  */
  --backdrop-background: rgba(0,0,0,.32);
}

/**
  * Common font family
  */
@font-face {
  font-family: pt-serif-bold;
  src: url("./fonts/PTSerif-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}
@font-face {
  font-family: roboto-regular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}
:root {
  /**
  * Font weight
  */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;
  /**
  * Common font family
  */
  --font-family-pt-serif-bold: pt-serif-bold;
  --font-family-roboto-bold: roboto-bold;
  --font-family-roboto-regular: roboto-regular;
  /**
  * Letter spacing
  */
  --letter-spacing-2: 0.2px;
  /**
  * Border width
  */
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;
  /**
  * Border radius
  */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --border-radius-999: 999px;
  /**
  * Font size
  */
  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-4: 4px;
  --font-size-8: 8px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --font-size-80: 80px;
  /**
  * Font style
  */
  --font-style-normal: normal;
  /**
  * Line Height
  */
  --line-height-16: 16px;
  --line-height-18: 18px;
  --line-height-24: 24px;
  --line-height-32: 32px;
  --line-height-40: 40px;
  /**
    *Elevations
    */
  --elevation-e1: 0px 1px 2px rgba(15, 26, 42, 0.25);
  --elevation-e2: 0px 0px 20px rgba(15, 26, 42, 0.15);
  --elevation-e3: 0px 10px 15px rgba(15, 26, 42, 0.1);
  --elevation-e4: 0px -2px 15px rgba(15, 26, 42, 0.1);
  --elevation-e5: 0px 3px 12px rgba(15, 26, 42, 0.25);
}

/* ------------------------> Container Card Style <------------------------ */
/*================================================================================
                                  Common Classes 
==================================================================================*/
/* ------------------------> Flex Behaviors <------------------------ */
.d-flex {
  display: flex;
}

.justify-content-start {
  justify-content: start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-start {
  align-items: start;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.flex-column {
  flex-direction: column;
}

.d-flex-column {
  display: flex;
  flex-direction: column;
}

.position_relative {
  position: relative;
}

.cursor-all-scroll {
  cursor: all-scroll !important;
}

.half-containers {
  flex: 1;
}

.gap-24 {
  gap: 24px;
}

.gap-4 {
  gap: 4px;
}

.gap-56 {
  gap: 56px;
}

.gap-40 {
  gap: 40px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mt-16 {
  margin-top: 16px;
}

/* ------------------------> Spacing Inside & Outside <------------------------ */
.m-0 {
  margin: var(--spacing-0) !important;
}

.p-0 {
  padding: var(--spacing-0) !important;
}

.pb-0 {
  padding-bottom: var(--spacing-0) !important;
}

.pb-24 {
  padding-bottom: var(--spacing-24) !important;
}

.pt-24 {
  padding-top: var(--spacing-24) !important;
}

.p-8 {
  padding: var(--spacing-8);
}

.p-16 {
  padding: var(--spacing-16);
}

.m-16 {
  margin: var(--spacing-16);
}

.pb-20 {
  padding: var(--spacing-20);
}

.margin-10 {
  margin: 10px !important;
}

.gap-20 {
  gap: var(--spacing-20);
}

.gap-16 {
  gap: var(--spacing-16);
}

.gap-12 {
  gap: var(--spacing-12);
}

.gap-8 {
  gap: var(--spacing-8);
}

.col-gap-20 {
  column-gap: 20px;
}

.col-gap-16 {
  column-gap: var(--spacing-14);
}

.col-gap-40 {
  column-gap: 40px;
}

.col-gap-12 {
  column-gap: var(--spacing-12);
}

.col-gap-14 {
  column-gap: var(--spacing-14);
}

.col-gap-8 {
  column-gap: var(--spacing-8);
}

.row-gap-8 {
  row-gap: var(--spacing-8);
}

snl-button.delete-button .snl-button[type^=primary] {
  background: var(--semantic-9) !important;
  color: var(--neutral-12) !important;
  border: none !important;
}
snl-button.delete-button .snl-button[type^=secondary]:not([disabled]) {
  color: var(--semantic-9);
  background-color: var(--neutral-12);
  border: var(--border-width-1) solid var(--semantic-9);
}
snl-button.delete-button .snl-button[type^=secondary]:not([disabled]):hover {
  background: none;
  color: var(--semantic-9);
}
snl-button.delete-button .snl-button[type^=tertiary]:not([disabled]) {
  color: var(--semantic-9);
  background-color: var(--neutral-12);
}
snl-button.delete-button .snl-button[type^=tertiary]:not([disabled]):hover {
  background: none;
  color: var(--semantic-9);
}

snl-modal .wrapper .modal__wrapper-medium {
  max-height: calc(100% - 80px) !important;
  height: auto !important;
}

/* ------------------------> Theme Colors <------------------------ */
.color__primary_default {
  color: var(--font-color-primary-default);
}

.color__primary {
  color: var(--font-color-primary);
}

.color__secondary {
  color: var(--font-color-secondary);
}

.color__neutral {
  color: var(--font-color-neutral);
}

.color__black {
  color: var(--font-color-black);
}

.primary-icon {
  --icon-color: var(--neutral-12) !important;
}

/* ------------------------> Alignment <------------------------ */
.text__center {
  text-align: center;
}

.text__start {
  text-align: start;
}

.text__end {
  text-align: right;
}

/* ------------------------> Font Weight <------------------------ */
.fw-700 {
  font-weight: var(--font-weight-700);
}

.fw-400 {
  font-weight: var(--font-weight-400) !important;
}

/* ------------------------> Border Radius <------------------------ */
.border__radius4 {
  border-radius: var(--border-radius-4);
}

.border__radius6 {
  border-radius: var(--border-radius-6);
}

.border__radius8 {
  border-radius: var(--border-radius-8);
}

.border__radius999 {
  border-radius: var(--border-radius-999);
}

.separator {
  border: var(--spacing-2) solid var(--neutral-9);
}

.text-decoration {
  text-decoration-line: underline;
}

.cursor-pointer {
  cursor: pointer;
}

/* Scroller CSS */
.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.separate-line {
  padding-bottom: var(--spacing-24);
  border-bottom: var(--spacing-1) solid var(--neutral-9);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);
}
.scroll::-webkit-scrollbar-thumb:hover {
  background-color: var(--scroll-thumb-hover);
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.df-s {
  display: flex;
  justify-content: start;
  align-items: start;
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

/* ------------------------> Common Reusable Style <------------------------ */
@media (max-width: 767px) {
  .theme-container {
    padding-top: 64px;
    min-height: calc(100vh - 137px);
  }
}
.theme-container .theme-container-inner {
  padding: var(--spacing-0) var(--spacing-24) var(--spacing-24) var(--spacing-24);
  min-height: 100vh;
  max-width: 1224px;
}
@media (max-width: 767px) {
  .theme-container .theme-container-inner {
    min-height: calc(100vh - 137px);
    padding: var(--spacing-16);
  }
}

.breadcrumb-main {
  padding: var(--spacing-32) var(--spacing-0);
}
.breadcrumb-main h1 {
  margin: 0px;
}
@media (max-width: 767px) {
  .breadcrumb-main {
    display: none;
  }
}

.section {
  border: var(--spacing-1) solid var(--neutral-9);
  background: var(--neutral-12);
  border-radius: var(--border-radius-4);
  padding: var(--spacing-16);
}

.CommonContainerCard {
  background: var(--neutral-12);
  padding: var(--spacing-24) var(--spacing-16);
  border: var(--spacing-1) solid var(--neutral-9);
  border-radius: var(--border-radius-4);
}
@media (max-width: 767px) {
  .CommonContainerCard {
    padding: var(--spacing-0);
    border: var(--spacing-0) solid var(--neutral-9);
    background: inherit;
  }
}
.CommonContainerCard.mt_0 {
  margin-top: 0;
}
.CommonContainerCard.card-mrgn-b-32 {
  margin-bottom: var(--spacing-32);
}
@media (max-width: 767px) {
  .CommonContainerCard.empty__records {
    padding: 0px 0px 16px 0px !important;
  }
  .CommonContainerCard.empty__records.action {
    padding: 16px 0px 16px 0px !important;
  }
}

.tab-page {
  background: var(--neutral-12);
  border: var(--spacing-1) solid var(--neutral-9);
}
@media (max-width: 767px) {
  .tab-page {
    background: inherit;
    border: var(--spacing-0) solid var(--neutral-9);
  }
}
.tab-page .tab {
  border-bottom: var(--spacing-1) solid var(--neutral-9);
  margin-top: 16px;
}
@media (max-width: 767px) {
  .tab-page .tab {
    margin-top: 0px;
    background: var(--neutral-12);
    padding-top: 8px;
  }
}
.tab-page .tab-content {
  padding: var(--spacing-24) var(--spacing-16);
}
@media (max-width: 767px) {
  .tab-page .tab-content {
    padding: var(--spacing-16);
  }
}

.border-body-tbl {
  border: var(--spacing-1) solid var(--neutral-9);
}

.word-wrap {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 24px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 1;
  color: var(--font-color-disabled);
}

.BottomStickyBar {
  position: sticky;
  left: var(--spacing-0);
  right: var(--spacing-0);
  bottom: var(--spacing-0);
  z-index: 99;
  max-width: 1224px;
  margin-left: 5px;
}
@media (max-width: 767px) {
  .BottomStickyBar {
    display: none;
  }
}

.BottomMobileBar {
  display: none;
}
@media (max-width: 767px) {
  .BottomMobileBar {
    display: block;
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
}

@media (max-width: 767px) {
  .add-footer .BottomMobileBar {
    bottom: 80px;
  }
}

.d-none {
  display: none;
}
@media (max-width: 991px) {
  .d-none {
    display: block;
  }
}

.m-none {
  display: block;
}
@media (max-width: 991px) {
  .m-none {
    display: none;
  }
}

.Skelton-pp {
  background: #e3e3e3;
  width: 100%;
  display: block;
  height: 10px;
}

.Skelton-fh {
  background: #C1C4C8;
  width: 100%;
  display: block;
  height: 10px;
}

.capitalize {
  text-transform: capitalize;
}

snl-page-title .content__body--title span {
  display: flex;
  color: #27364b;
  align-items: center;
  gap: 4px;
}
snl-page-title .content__body--title snl-icon {
  padding: 4px 8px;
}
snl-page-title .content__body--title h1 {
  margin: 0px;
}

.input__disabled .inp {
  background: var(--neutral-11) !important;
  color: var(--font-color-disabled) !important;
  pointer-events: none;
}
.input__disabled .inp label {
  background: var(--neutral-11) !important;
}
.input__disabled .inp .inp__container--input {
  color: #B8BFCA;
}

/* ------------------------> Filters Dropdown Style <------------------------ */
.CommonFiltersPanel {
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  flex-wrap: initial;
  gap: var(--spacing-16);
}
.CommonFiltersPanel .FiltersHolder {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.CommonFiltersPanel .ResetFiltersTxt {
  height: 48px;
}
@media screen and (max-width: 1300px) {
  .CommonFiltersPanel .ResetFiltersTxt {
    width: 124px;
  }
}
.CommonFiltersPanel .ResetFiltersTxt .ResetAllTxt {
  color: var(--font-color-disabled);
  text-decoration-line: none;
  cursor: not-allowed;
}
.CommonFiltersPanel .ResetFiltersTxt .ResetAllTxt.active {
  color: var(--primary-default);
  cursor: pointer;
}

.TableWrapper {
  margin-top: var(--spacing-24);
  overflow-x: auto;
  overflow-y: auto;
  border: var(--spacing-1) solid var(--neutral-9);
  border-radius: var(--spacing-8);
}
@media (max-width: 991px) {
  .TableWrapper {
    margin-top: var(--spacing-8);
    border: var(--spacing-0) solid var(--neutral-9);
  }
}

.w-100 {
  width: 100%;
}

.w-20 {
  width: 22%;
}

.w-25 {
  width: 25%;
}

.empty-section .not-found .image-area {
  width: 100px;
  height: 100px;
  padding: 5px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-radius: var(--border-radius-999);
  background: #F1F4F9;
  margin-bottom: 16px;
}

.skeleton {
  background-color: #e2e5e7;
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0));
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}
@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
.skeleton.profile-thumb {
  width: 48px;
  height: 48px;
  border-radius: var(--border-radius-999);
}
.skeleton.personal-image {
  width: 160px;
  height: 160px;
}
.skeleton.input {
  width: 100%;
  border-radius: 4px;
  height: 48px;
}
.skeleton.wPx-100 {
  width: 100px;
}
.skeleton.dropdown {
  width: 130px;
  height: 48px;
  border-radius: 4px;
}
.skeleton.search {
  width: 260px;
  height: 48px;
  border-radius: 4px;
}
.skeleton.button {
  width: 200px;
  height: 48px;
  border-radius: 4px;
}
@media (max-width: 767px) {
  .skeleton.button {
    width: inherit;
  }
}
.skeleton.mobile-button {
  width: 48px;
  height: 48px;
  border-radius: 4px;
}
.skeleton.toogle {
  width: 60px;
  height: 30px;
  border-radius: 999px;
}
.skeleton.text {
  margin-top: 8px;
  width: 130px;
  height: 10px;
}
.skeleton.heading {
  width: 160px;
  height: 18px;
}
.skeleton.list {
  height: 47px;
  margin-bottom: 1px;
}
.skeleton.w-100 {
  width: 100%;
}
.skeleton.w-80 {
  width: 80%;
}
.skeleton.w-50 {
  width: 50%;
}
.skeleton.w-20 {
  width: 20%;
}
.skeleton.tabskeleton {
  margin-top: 8px;
  width: 120px;
  height: 32px;
  border-radius: 4px 4px 0px 0px;
}
.skeleton.drga-drop {
  border-radius: var(--border-radius-8);
  min-height: 114px;
  margin-bottom: 12px;
}
.skeleton.upload-image {
  border-radius: var(--border-radius-8);
  min-height: 80px;
  margin-bottom: 12px;
}
.skeleton.text-area {
  width: 100%;
  height: 220px;
  border-radius: 4px;
}

.container__dropdown-border {
  width: 100% !important;
}

.ng2-pdf-viewer-container::-webkit-scrollbar {
  width: 8px !important;
}
.ng2-pdf-viewer-container::-webkit-scrollbar-track {
  border-radius: 8px !important;
  background: var(--neutral-9) !important;
}
.ng2-pdf-viewer-container::-webkit-scrollbar-thumb {
  border-radius: 8px !important;
  background: var(--neutral-13) !important;
}
.ng2-pdf-viewer-container::-webkit-scrollbar-thumb:hover {
  background: var(--Neutral-N9, #8798A9) !important;
}

.turncated-text {
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 30px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.turncated-text:hover {
  display: flex;
  max-height: inherit;
}

snl-row.active {
  background-color: var(--neutral-11) !important;
}

snl-button.delete-button .snl-button[type^=primary] {
  background: var(--semantic-9) !important;
  color: var(--neutral-12) !important;
  border: none !important;
}

.th__wrapper .th__checkbox {
  margin-left: 4px;
}

snl-table snl-row .col104 {
  min-width: 104px;
  width: 104px;
  max-width: 104px;
}
snl-table snl-row .col124 {
  min-width: 124px;
  width: 124px;
  max-width: 124px;
}
snl-table snl-row .col102 {
  min-width: 102px;
  width: 102px;
  max-width: 102px;
}
snl-table snl-row .col114 {
  min-width: 114px;
  width: 114px;
  max-width: 114px;
}
snl-table snl-row .col96 {
  min-width: 96px;
  width: 96px;
  max-width: 96px;
}
snl-table snl-row .col130 {
  min-width: 130px;
  width: 130px;
  max-width: 130px;
}
snl-table snl-row .col150 {
  min-width: 150px;
  width: 150px;
  max-width: 150px;
}
snl-table snl-row .col160 {
  min-width: 160px;
  width: 160px;
  max-width: 160px;
}
snl-table snl-row .col175 {
  min-width: 175px;
  width: 175px;
  max-width: 175px;
}
snl-table snl-row .col400 {
  min-width: 400px;
  width: 400px;
  max-width: 400px;
}

.pills {
  border-radius: 8px;
  background-color: #CBD4E1;
  padding: 2px 8px;
  font-weight: 700;
  line-height: 18px;
  font-size: 12px;
}

.breadcrumb-main {
  padding: var(--spacing-32) var(--spacing-0);
}

.navigation__panel-content {
  width: 100% !important;
}
.navigation__panel-content.navigation__panel-content--selected {
  width: inherit !important;
}

.hiddenCanvasElement {
  display: none;
}

.MainCkeditor .ck-editor__editable_inline.ck-focused {
  box-shadow: none !important;
}
.MainCkeditor .ck-editor__editable_inline ol, .MainCkeditor .ck-editor__editable_inline ul {
  padding-left: 32px !important;
}

.ck-balloon-panel {
  display: none !important;
}

.capitalize-first::first-letter {
  text-transform: capitalize;
}

.ck-editor__editable {
  min-height: calc(100vh - 516px);
}
@media screen and (max-width: 1400px) {
  .ck-editor__editable {
    min-height: calc(100vh - 360px);
  }
}

.ck-editor__editable_inline {
  padding-left: 24px !important;
}