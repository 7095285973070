/**
  * Common font family
  */
@font-face {
  font-family: pt-serif-bold;
  src: url("./fonts/PTSerif-Bold.ttf") format("truetype");
}

@font-face {
  font-family: roboto-bold;
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
}

@font-face {
  font-family: roboto-regular;
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
}

:root {
  /**
  * Font weight
  */
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;
  --font-weight-900: 900;

  /**
  * Common font family
  */
  --font-family-pt-serif-bold: pt-serif-bold;
  --font-family-roboto-bold: roboto-bold;
  --font-family-roboto-regular: roboto-regular;

  /**
  * Letter spacing
  */
  --letter-spacing-2: 0.2px;

  /**
  * Border width
  */
  --border-width-1: 1px;
  --border-width-2: 2px;
  --border-width-4: 4px;
  --border-width-8: 8px;

  /**
  * Border radius
  */
  --border-radius-4: 4px;
  --border-radius-8: 8px;
  --border-radius-6: 6px;
  --border-radius-999: 999px;

  /**
  * Font size
  */
  --font-size-1: 1px;
  --font-size-2: 2px;
  --font-size-4: 4px;
  --font-size-8: 8px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --font-size-80: 80px;

  /**
  * Font style
  */
  --font-style-normal: normal;

  /**
  * Line Height
  */
  --line-height-16: 16px;
  --line-height-18: 18px;
  --line-height-24: 24px;
  --line-height-32: 32px;
  --line-height-40: 40px;

  /**
    *Elevations
    */

  --elevation-e1: 0px 1px 2px rgba(15, 26, 42, 0.25);
  --elevation-e2: 0px 0px 20px rgba(15, 26, 42, 0.15);
  --elevation-e3: 0px 10px 15px rgba(15, 26, 42, 0.1);
  --elevation-e4: 0px -2px 15px rgba(15, 26, 42, 0.1);
  --elevation-e5: 0px 3px 12px rgba(15, 26, 42, 0.25);

}